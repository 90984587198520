import React, { useContext } from "react";
import { baseMargin, iconArrowRightCircle } from "../../../util/ui_util";
import { AppContext } from "../../../context/app/AppState";
import bannerVideo from '../../../assets/videos/banner_video.mp4'

export const HomeBanner = () => {
  const { accentColor } = useContext(AppContext);
  const videoUrl =
    "https://www.wellingtoninternationalschool.com/-/media/Project/GEMS/WIS_GEMS_Wellington_International_School/_Hero-Banners/1759987938_wos.mp4";

  return (
    <div className="relative w-full h-[80vh] overflow-hidden">
      <video
        className="absolute left-1/2 w-full h-full object-cover transform -translate-x-1/2"
        autoPlay
        loop
        muted
      >
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className={`absolute inset-0 flex flex-col justify-end pb-32 md:w-[30vw] ${baseMargin} lg:mx-48`}
      >
        <h1 className="text-2xl md:text-4xl text-white mb-8 select-none text-3d">
          A FUTURE-FOCUSED LEARNING ENVIRONMENT
        </h1>
        <div
          className="flex flex-row space-x-4 rtl:space-x-reverse items-center cursor-pointer"
          onClick={() => {}}
        >
          <p className="text-base md:text-lg text-white select-none text-3d">
            WHY CHOOSE GMS
          </p>
          {iconArrowRightCircle({
            fillColor: accentColor,
            width: "1.5em",
            height: "1.5em",
          })}
        </div>
      </div>
    </div>
  );
};
