const AppReducer = (state, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };

    case "SET_IS_DARK_MODE":
      return {
        ...state,
        isDarkMode: action.payload,
      };

    case "SET_STOP_SCROLL":
      return {
        ...state,
        stopScroll: action.payload,
      };

    case "SET_SHOW_LOGIN_DIALOG":
      return {
        ...state,
        showLoginDialog: action.payload,
        stopScroll: action.payload,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        token: {
          ...state.token,
          data: null,
          loading: false,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default AppReducer;
