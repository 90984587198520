import React, { useContext } from "react";
import { dummyCategories } from "../../../util/dummy";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { iconEmail, iconHome, iconPhone } from "../../../util/ui_util";
import { FooterContactUsLink } from "./FooterContactUsLink";

export const FooterLinks = () => {
  const navigationCategories = dummyCategories.slice(0, 3);
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      {navigationCategories.map((category, index) => (
        <div
          key={index}
          className="flex flex-col space-y-4 lg:flex-shrink-0 lg:flex-grow-0 lg:basis-[20%]"
        >
          <div
            className="w-16 h-[1px]"
            style={{ backgroundColor: primaryColor }}
          ></div>
          <div className="font-bold" style={{ color: primaryColor }}>
            {category.name}
          </div>
          <div className="flex flex-col space-y-2">
            {category.categories.map((sub, index) => (
              <div
                key={index}
                className="text-sm text-gray-500 hover:text-gray-400 cursor-pointer"
                onClick={() => {}}
              >
                {sub.name}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="flex flex-col space-y-4 lg:flex-shrink-0 lg:flex-grow-0 lg:basis-[20%]">
        <div
          className="w-16 h-[1px]"
          style={{ backgroundColor: primaryColor }}
        ></div>
        <div className="font-bold" style={{ color: primaryColor }}>
          {t("gmsGulfModelSchool")}
        </div>
        <div className="flex flex-col space-y-2">
          <FooterContactUsLink
            text={
              "Al Sufouh Area, Sheikh Zayed Road, Dubai, United Arab Emirates"
            }
            icon={iconHome}
            onClick={() => {}}
          />
          <FooterContactUsLink
            text={"reception_wis@gemsedu.com"}
            icon={iconEmail}
            onClick={() => {}}
          />
          <FooterContactUsLink
            text={"+971 4 307 3000"}
            icon={iconPhone}
            onClick={() => {}}
          />
        </div>
      </div>
    </>
  );
};
