import React from "react";

export const ButtonWithAnimatedOpacity = ({
  text,
  bgColor,
  textColor,
  onClick,
}) => {
  return (
    <button
      className="px-10 py-5 w-full transition-opacity text-xs font-bold duration-300 hover:opacity-75"
      style={{ color: textColor, backgroundColor: bgColor }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
