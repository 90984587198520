import React, { useContext, useRef } from "react";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { dummyHomeCategories } from "../../../util/dummy";
import { ItemHomeCategory } from "./items/ItemHomeCategory";
import {
  basePadding,
  iconArrowRightCircle,
  PurpleDivider,
} from "../../../util/ui_util";

export const HomeCategories = () => {
  const { primaryColor, accentColor } = useContext(AppContext);
  const { t } = useTranslation();
  const categories = dummyHomeCategories;

  const scrollContainerRef = useRef(null);

  const scrollByElement = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount =
        scrollContainerRef.current.firstChild.offsetWidth + 16; // Item width + space-x-4 gap (16px)
      scrollContainerRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`flex flex-col items-center ${basePadding} md:px-0 py-16 bg-backgroundLight dark:bg-backgroundDark`}
    >
      <h2
        className="font-bold text-center text-2xl md:text-4xl"
        style={{ color: accentColor }}
      >
        {t("welcomeToGMS")}
      </h2>
      <h2
        className="font-bold text-3xl md:text-6xl mt-8 w-full mx-auto container text-center"
        style={{ color: primaryColor }}
      >
        {t("homeText1")}
      </h2>
      <div className="mt-12">
        <PurpleDivider />
      </div>
      <p className="text-textLight dark:text-textDark w-full mx-auto container mt-12 text-center">
        {t("homeText2")}
      </p>
      <div className="w-full mx-auto container overflow-x-scroll mt-12">
        <div className="flex flex-row space-x-2 rtl:space-x-reverse w-full">
          {/* Left Arrow */}
          <button
            className="rotate-180"
            onClick={() => scrollByElement("left")}
          >
            {iconArrowRightCircle({
              fillColor: "#CCCCCC",
              width: "2em",
              height: "2em",
            })}
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollContainerRef}
            className="w-full flex space-x-4 rtl:space-x-reverse overflow-x-scroll"
          >
            {categories.map((category, index) => (
              <ItemHomeCategory key={index} category={category} />
            ))}
          </div>

          {/* Right Arrow */}
          <button onClick={() => scrollByElement("right")}>
            {iconArrowRightCircle({
              fillColor: "#CCCCCC",
              width: "2em",
              height: "2em",
            })}
          </button>
        </div>
      </div>
    </div>
  );
};
