import { Route, Routes } from "react-router-dom";
import useScrollToTop from "./hooks/useScrollToTop";
import CombinedProviders from "./context/CombinedProviders";
import { HomePage } from "./components/pages/home/HomePage";
import useDarkMode from "./hooks/useDarkMode";

function App() {
  useScrollToTop();
  useDarkMode();
  return (
    <CombinedProviders>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </CombinedProviders>
  );
}

export default App;
