export const dummyBannerData = [
  {
    id: 1,
    text: "Breaking News: New React features released!",
    url: "https://google.com",
  },
  {
    id: 2,
    text: "Update: Tailwind CSS v3.0 is now available!",
    url: "https://google.com",
  },
  {
    id: 3,
    text: "Announcement: Join our webinar on modern web development!",
    url: "https://google.com",
  },
];

export const homeSliderImages = [
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
];

export const dummyFooterPartners = [
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_design-images/wis_logo.svg?h=100&w=300&hash=53784312C078EEDC72C9C91AFA5C0286",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/shared/client-img-3.jpg?h=83&w=201&hash=1584E8311D259951929F8C7657526BD9",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/shared/_shared-footer-logos/cobis_accredited_member_logo_no-margin.svg?h=85&w=170&hash=6D91F407D5EBB9E02A4CDA46CA9630D0",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/misc/bso_wis.png?h=83&w=200&hash=53CF7E4F89DBDAAE5DFBC912573DDD13",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_generic-content-images/rosette_wis__.png?h=75&w=170&hash=2C0F066D8194A52A34EF9ABC6B784C9B",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/cobis_new.png?h=85&w=170&hash=A0ED03F3476949A30A7DDB9308F486F4",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_design-images/bsme-logo-footer.png?h=40&w=250&hash=B1D88CEB8BA321260538A7A58D9ECC04",
];

export const dummySocials = [
  {
    id: 7,
    name: "facebook",
    link: "https://tootalmasif.softlix.net/",
    url: "https://tootalmasif.softlix.net/",
    image:
      "https://tootalmasif.softlix.net/dash/storage/app/public/ecommerce/images.png",
    status: 1,
    created_at: null,
    updated_at: null,
  },
  {
    id: 6,
    name: "instagram",
    link: "https://tootalmasif.softlix.net/",
    url: "https://tootalmasif.softlix.net/",
    image:
      "https://tootalmasif.softlix.net/dash/storage/app/public/ecommerce/R.png",
    status: 1,
    created_at: null,
    updated_at: null,
  },
];

export const dummyCategories = [
  {
    id: 1,
    name: "About us",
    image: "",
    categories: [
      {
        id: 7,
        name: "Read about our school",
        image: "",
      },
      {
        id: 8,
        name: "Welcome Message from the Principal/CEO",
        image: "",
      },
      {
        id: 9,
        name: "Vision and Mission Statement",
        image: "",
      },
      {
        id: 10,
        name: "Our Team",
        image: "",
        categories: [
          {
            id: 58,
            name: "Executive Team",
            image: "",
          },
          {
            id: 59,
            name: "Senior Leadership Team",
            image: "",
          },
          {
            id: 60,
            name: "Curriculum Leadership Team",
            image: "",
          },
          {
            id: 61,
            name: "Heads of Year",
            image: "",
          },
          {
            id: 62,
            name: "Front of House Team",
            image: "",
          },
        ],
      },
      {
        id: 11,
        name: "Our Campus and Facilities",
        image: "",
        categories: [
          {
            id: 63,
            name: "Our Location",
            image: "",
          },
          {
            id: 64,
            name: "Facilities",
            image: "",
          },
        ],
      },
      {
        id: 12,
        name: "Our Community",
        image: "",
        categories: [
          {
            id: 65,
            name: "Parent Association",
            image: "",
          },
          {
            id: 66,
            name: "GMS Alumni",
            image: "",
          },
        ],
      },
      {
        id: 13,
        name: "Academic calendar",
        image: "",
      },
      {
        id: 14,
        name: "Key Documents and Policies",
        image: "",
      },
      {
        id: 15,
        name: "Working With Us",
        image: "",
      },
    ],
  },
  {
    id: 2,
    name: "Why GMS",
    image: "",
    categories: [
      {
        id: 16,
        name: "Academic Performance",
        image: "",
      },
      {
        id: 17,
        name: "University Destinations",
        image: "",
      },
      {
        id: 18,
        name: "Accreditations",
        image: "",
      },
      {
        id: 19,
        name: "High Performance Learning",
        image: "",
      },
      {
        id: 20,
        name: "Inspections Reports",
        image: "",
      },
      {
        id: 21,
        name: "Parent Testimonials",
        image: "",
      },
      {
        id: 22,
        name: "The GMS Difference",
        image: "",
      },
    ],
  },
  {
    id: 3,
    name: "Admissions",
    image: "",
    categories: [
      {
        id: 23,
        name: "Virtual Admission Centre",
        image: "",
      },
      {
        id: 24,
        name: "Admissions Overview",
        image: "",
      },
      {
        id: 25,
        name: "Admissions Process",
        image: "",
      },
      {
        id: 26,
        name: "Age Requirements and Grade Placements",
        image: "",
      },
      {
        id: 27,
        name: "Tuition Fees",
        image: "",
      },
      {
        id: 28,
        name: "Scholarships",
        image: "",
      },
      {
        id: 29,
        name: "Book a Tour",
        image: "",
      },
      {
        id: 30,
        name: "Enroll Online",
        image: "",
      },
      {
        id: 31,
        name: "Transfer Certificate",
        image: "",
      },
    ],
  },
  {
    id: 4,
    name: "Curriculum",
    image: "",
    categories: [
      {
        id: 32,
        name: "Learning Overview",
        image: "",
      },
      {
        id: 33,
        name: "Lower School",
        image: "",
      },
      {
        id: 34,
        name: "Middle School",
        image: "",
      },
      {
        id: 35,
        name: "Upper School",
        image: "",
      },
      {
        id: 36,
        name: "IB",
        image: "",
        categories: [
          {
            id: 67,
            name: "IB Programme Overview",
            image: "",
          },
          {
            id: 68,
            name: "The Core Elements",
            image: "",
          },
          {
            id: 69,
            name: "The IB Advantage",
            image: "",
          },
          {
            id: 70,
            name: "The IB Learner Profile",
            image: "",
          },
          {
            id: 71,
            name: "IB Pathways Information",
            image: "",
          },
          {
            id: 72,
            name: "IB Results and Destinations",
            image: "",
          },
          {
            id: 73,
            name: "IB Career-related Programme Overview",
            image: "",
          },
          {
            id: 74,
            name: "The IBCP Core Elements",
            image: "",
          },
        ],
      },
      {
        id: 37,
        name: "Inclusion",
        image: "",
      },
      {
        id: 38,
        name: "Careers Education and Guidance",
        image: "",
      },
    ],
  },
  {
    id: 5,
    name: "Student Life",
    image: "",
    categories: [
      {
        id: 39,
        name: "Latest Stories",
        image: "",
      },
      {
        id: 40,
        name: "Student Leadership",
        image: "",
      },
      {
        id: 41,
        name: "Explore Enrich Excite",
        image: "",
      },
      {
        id: 42,
        name: "Wellbeing",
        image: "",
      },
      {
        id: 43,
        name: "Innovation",
        image: "",
      },
      {
        id: 44,
        name: "Rewards",
        image: "",
      },
      {
        id: 45,
        name: "Duke of Edinburgh International Award",
        image: "",
      },
      {
        id: 46,
        name: "Creativity, Activity & Service",
        image: "",
      },
      {
        id: 47,
        name: "External Extra Curricular Activities",
        image: "",
      },
    ],
  },
  {
    id: 6,
    name: "For Parents",
    image: "",
    categories: [
      {
        id: 48,
        name: "Our Clinic",
        image: "",
      },
      {
        id: 49,
        name: "Parent Association",
        image: "",
      },
      {
        id: 50,
        name: "Academic Calendar",
        image: "",
      },
      {
        id: 51,
        name: "School Transport",
        image: "",
      },
      {
        id: 52,
        name: "Parent Handbooks",
        image: "",
      },
      {
        id: 53,
        name: "Yearbook",
        image: "",
      },
      {
        id: 54,
        name: "Uniform",
        image: "",
      },
      {
        id: 55,
        name: "A Note From The Founder",
        image: "",
      },
      {
        id: 56,
        name: "GMS Rewards",
        image: "",
      },
      {
        id: 57,
        name: "Feedback & Suggestions",
        image: "",
      },
    ],
  },
];

export const dummyNotifications = [
  {
    id: 1,
    title: "Order #321",
    body: "Ready for shipment",
    created_at: "2024-10-22T22:43:59.000000Z",
  },
  {
    id: 2,
    title: "Order #134",
    body: "Cancelled by admin",
    created_at: "2024-10-22T22:43:59.000000Z",
  },
];

export const dummyHomeCategories = [
  {
    id: 1,
    name: "Academic Performance",
    description: "Our academic results over the years",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/academic-performance-tn.jpg?h=400&w=490&hash=5CC0588FA79ED2A4BC371408CABC1B1A",
  },
  {
    id: 2,
    name: "University Destinations",
    description:
      "GMS students are accepted by some of the world's most prestigious universities",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/misc/uni-destinations-thumb.jpg?h=400&w=490&hash=016FC76FC74A30EC23E23EA1F62221F8",
  },
  {
    id: 3,
    name: "School Awards",
    description: "Celebrating the various awards and success at GMS",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/explore-enrich-excite/duke-of-edinburgh/490-x400/dsc_1867.png?h=400&w=490&hash=135024AB5AC18587B69B0942B321E79D",
  },
  {
    id: 4,
    name: "High Performance Learning",
    description: "A research-based pedagogy-led philosophy",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/hpl-tn.jpg?h=400&w=490&hash=201739059DC93D3CCA08A553F440EBF7",
  },
  {
    id: 5,
    name: "Outstanding Since 2009",
    description: "GMS is rated Outstanding by DSIB since 2009",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/general-photos-490-x400/school-1.png?h=400&w=490&hash=F492DACA425A00A7C1500E6BC497D854",
  },
];

export const dummyHomeResults = [
  {
    number: "45",
    text: "Points achieved by five students putting them in the top 0.1% worldwide",
  },
  {
    number: "38",
    text: "Average points achieved by Diploma Students. An impressive result as the world average point score is 30",
  },
  {
    number: "35%",
    text: "Of students achieved 40 points or higher",
  },
  {
    number: "71%",
    text: "Of students achieved 35 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
];

export const dummyHomeSchools = [
  {
    id: 1,
    name: "Lower School",
    description:
      "Through a rich, diverse and exciting curriculum offer, with access to subject specialists, our students develop into well rounded, confident learners pursuing 'excellence' in all that they do",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_hero-banners/lower-school-homepage.jpg?h=980&w=1335&hash=DF7ACB9A1E5781649E3996A5E6B5692A",
  },
  {
    id: 2,
    name: "Middle School",
    description:
      "As our students move into the Middle School, they have further exposure to our subject specialists in every area of their learning",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_hero-banners/middle-school-homepage.jpg?h=980&w=1335&hash=22ADB1B37E42D9B5A329025F76CAC480",
  },
  {
    id: 3,
    name: "Upper School",
    description: "Upper SChool promotes an incredibly strong strong sense of independence, where students take pride in their achievements",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_hero-banners/upper-school-homepage.jpg?h=980&w=1335&hash=0E7C36F66F4811295D60F3F43420517F",
  },
  {
    id: 4,
    name: "International Baccalaureate",
    description: "We take great pride in promoting academic excellence as well as individual betterment and endeavour",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_hero-banners/ib-homepage.jpg?h=980&w=1335&hash=9CF960E1EB63DF2FF040DF93B237810A",
  },
];
