import React, { useContext } from "react";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { AppButton } from "../../base/AppButton";
import { ItemHomeButton } from "./items/ItemHomeButton";
import iconStudent from "../../../assets/images/ic_student.svg";
import iconBook from "../../../assets/images/ic_book.svg";
import iconCalender from "../../../assets/images/ic_calender.svg";

export const HomeButtons = () => {
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full bg-backgroundLight dark:bg-backgroundDark py-8">
      <div
        className="flex flex-col w-full items-center py-16"
        style={{ backgroundColor: primaryColor }}
      >
        <h1 className="font-bold text-4xl text-white text-center">{t("whyGms")}</h1>
        <h1 className="text-white text-center mt-4">{t("whyGmsExplanation")}</h1>
        <div className="w-[200px] mt-8">
          <AppButton
            text={t("exploreNow").toUpperCase()}
            bgColor={"transparent"}
            bgHoverColor={"transparent"}
            borderColor={"white"}
            borderHoverColor={"white"}
            textColor={"white"}
            textHoverColor={"white"}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-wrap md:space-y-0 md:gap-x-4 md:gap-y-2 lg:flex-row lg:flex-nowrap lg:justify-between lg:gap-x-0 lg:gap-y-0 lg:space-x-4 rtl:space-x-reverse mt-8">
        <ItemHomeButton
          text={t("admissions")}
          icon={iconStudent}
          onClick={() => {}}
        />
        <ItemHomeButton
          text={t("location")}
          icon={iconBook}
          onClick={() => {}}
        />
        <ItemHomeButton
          text={t("enrolFromHome")}
          icon={iconCalender}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};
