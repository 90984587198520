import React, { useContext } from "react";
import { AppContext } from "../../../../context/app/AppState";
import { PurpleDivider } from "../../../../util/ui_util";
import { AppButton } from "../../../base/AppButton";
import { useTranslation } from "react-i18next";

export const ItemHomeSchool = ({ school, isSelected }) => {
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      <div
        className="hidden md:block relative flex-none w-1/2 h-[600px] bg-cover bg-center select-none overflow-y-clip"
        style={{
          backgroundImage: `url(${school.image})`,
        }}
      >
        <div
          className={`w-full h-full ${
            isSelected ? "opacity-80" : "opacity-90"
          }`}
          style={{
            backgroundImage: `linear-gradient(to bottom, ${
              isSelected ? "rgba(255,255,255, 0)" : primaryColor
            }, ${isSelected ? "#0c0c0c" : "#09132d"})`,
          }}
        ></div>
        <div
          className={`absolute inset-0 top-1/2 start-[80px] text-white font-bold text-3xl transition-all duration-500 ${
            isSelected ? "translate-y-[-30px]" : "translate-y-0"
          }`}
        >
          {school.name}
        </div>
        <div
          className={`absolute inset-0 top-[60%] start-[80px] pe-32 transition-all duration-500 ${
            isSelected
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-full"
          }`}
        >
          <div className="w-[50px]">
            <PurpleDivider />
          </div>
          <h4 className="text-white mt-[5%]">{school.description}</h4>
          <div className="w-[200px] mt-4">
            <AppButton
              text={t("learnMore")}
              bgColor={"transparent"}
              bgHoverColor={"transparent"}
              borderColor={"white"}
              borderHoverColor={"white"}
              textColor={"white"}
              textHoverColor={"white"}
            />
          </div>
        </div>
      </div>
      {/* Phone Layout */}
      <div className="flex flex-none md:hidden flex-col w-full">
        <img src={school.image} alt="" className="w-full h-[300px]" />
        <div
          className="w-full h-[300px] flex flex-col items-center py-10"
          style={{
            backgroundImage: `linear-gradient(to bottom, ${primaryColor}, #09132d)`,
          }}
        >
          <div className="text-white font-bold text-3xl text-center">
            {school.name}
          </div>
          <div className="w-[50px] mt-4">
            <PurpleDivider />
          </div>
          <h4 className="text-white mt-[5%] text-center text-sm">{school.description}</h4>
          <div className="w-[200px] mt-8">
            <AppButton
              text={t("learnMore")}
              bgColor={"transparent"}
              bgHoverColor={"transparent"}
              borderColor={"white"}
              borderHoverColor={"white"}
              textColor={"white"}
              textHoverColor={"white"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
