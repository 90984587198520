import React, { useState } from "react";

export const FooterContactUsLink = ({ text, icon, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="flex flex-row space-x-2 rtl:space-x-reverse cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <div>
        {icon({ fillColor: "#609A9B", width: "1.5em", height: "1.5em" })}
      </div>
      <div
        className={`text-sm ${isHovered ? "text-gray-400" : "text-gray-500"}`}
      >
        {text}
      </div>
    </div>
  );
};
