import React, { useState } from "react";
import { iconArrowRightCircle, spinner } from "../../util/ui_util";
import colors from "../../util/colors";

export const AppButton = ({
  text,
  type,
  bgColor,
  bgHoverColor,
  borderColor,
  borderHoverColor,
  textColor,
  textHoverColor,
  useBorder = true,
  isLoading,
  isDisabled,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const buttonSpinner = spinner();

  const handleClick = (e) => {
    if (isLoading || isDisabled) {
      e.preventDefault();
      return;
    }
    if (type !== "submit") {
      if (onClick) {
        onClick();
      }
      e.preventDefault();
      return;
    }
  };

  return (
    <button
      type={type}
      className="flex items-center justify-center w-full px-4 h-12 border-2 cursor-pointer select-none relative overflow-hidden"
      style={{
        backgroundColor: isDisabled
          ? colors.disabledButton
          : isHovered
          ? bgHoverColor
          : bgColor,
        borderColor:
          useBorder && !isDisabled
            ? isHovered
              ? borderHoverColor
              : borderColor
            : "transparent",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isLoading ? (
        buttonSpinner
      ) : (
        <div className="relative flex items-center px-0 w-full">
          {/* Text */}
          <div
            className={`absolute w-full text-start transition-all duration-[400ms] ${
              isHovered ? "translate-x-1/4" : "translate-x-0"
            }`}
            style={{ color: isHovered ? textHoverColor : textColor }}
          >
            {text}
          </div>

          {/* Icon */}
          <div
            className={`absolute right-4 transition-all duration-[400ms] ${
              isHovered
                ? "opacity-0 translate-x-4"
                : "opacity-100 translate-x-0"
            }`}
          >
            {iconArrowRightCircle({
              fillColor: isHovered ? textHoverColor : textColor,
              width: "1.5em",
              height: "1.5em",
            })}
          </div>
        </div>
      )}
    </button>
  );
};
