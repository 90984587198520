import { AppProvider } from "./app/AppState";
import { SnackbarProvider } from "./general/SnackbarContext";

const CombinedProviders = ({ children }) => {
  return (
    <SnackbarProvider>
      <AppProvider>{children}</AppProvider>
    </SnackbarProvider>
  );
};
export default CombinedProviders;
