import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { iosArrowRight } from "../../../util/ui_util";
import { AppContext } from "../../../context/app/AppState";

export const ItemNavigationSubcategory = ({
  category,
  isRtl,
  flipPosition = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubcategories =
    category?.categories && category.categories.length > 0;
  const { primaryColor } = useContext(AppContext);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <Link to={`/category/${category.id}`} draggable={false}>
      <div
        className="relative cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`flex items-center w-full justify-between ${
            isOpen ? "bg-[#29373A]" : "bg-transparent"
          } py-2 ps-4 border border-b-gray-300`}
          style={{ color: isOpen ? "white" : primaryColor }}
        >
          {category?.name}
          {hasSubcategories && (
            <div className="flex flex-row">
              <div
                className={`transition-transform ${isRtl ? "rotate-180" : ""}`}
              >
                {iosArrowRight()}
              </div>
              <div className="w-[10px]"></div>
            </div>
          )}
        </div>
        {isOpen && hasSubcategories && (
          <div
            className={`absolute w-[250px] bg-[#E3E6E6] shadow-lg z-50 top-0 ${
              isRtl
                ? flipPosition
                  ? "left-[250px]"
                  : "right-[250px]"
                : flipPosition
                ? "right-[250px]"
                : "left-[250px]"
            }`}
          >
            {category.categories?.map((sub, index) => (
              <div
                key={index}
                className="py-2 px-2 bg-transparent hover:bg-[#29373A] border border-b-gray-300 txt-hvr-white"
              >
                <Link to={`/category/${sub.id}`} draggable={false}>
                  {sub.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};
