import React from "react";
import { Layout } from "../../base/Layout";
import { HomeBanner } from "./HomeBanner";
import { HomeCategories } from "./HomeCategories";
import { HomePrincipalMessage } from "./HomePrincipalMessage";
import { HomeResults } from "./HomeResults";
import { HomeSchools } from "./HomeSchools";
import { HomeButtons } from "./HomeButtons";

export const HomePage = () => {
  return (
    <Layout>
       <div>
          <HomeBanner />
          <HomeCategories />
          <HomePrincipalMessage />
          <HomeResults />
          <HomeSchools />
          <HomeButtons />
        </div>
    </Layout>
  );
};
