import React, { useContext } from "react";
import { AppContext } from "../../../../context/app/AppState";

export const ItemHomeResult = ({ number, text }) => {
  const { primaryColor } = useContext(AppContext);

  return (
    <div className="flex flex-col items-center space-y-4 flex-none select-none">
      <h1
        className="text-6xl font-bold text-center"
        style={{ color: primaryColor }}
      >
        {number}
      </h1>
      <h1 className="text-xl text-center" style={{ color: primaryColor }}>
        {text}
      </h1>
    </div>
  );
};
