import React, { useState, useRef, useEffect } from "react";
import { dummyHomeSchools } from "../../../util/dummy";
import { ItemHomeSchool } from "./items/ItemHomeSchool";
import { iconArrowRightCircle } from "../../../util/ui_util";

const ArrowButton = ({ isLeft = false, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`cursor-pointer ${isLeft && "rotate-180"}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {iconArrowRightCircle({
        fillColor: isHovered ? "#d4af37" : "white",
        width: "2em",
        height: "2em",
      })}
    </div>
  );
};

export const HomeSchools = () => {
  const schools = dummyHomeSchools;
  const [selectedSchool, setSelectedSchool] = useState(0);
  const [divider, setDivider] = useState(2);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        setDivider(2);
      } else {
        setDivider(1);
      }
    };
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  const handleLeftArrowClick = () => {
    setSelectedSchool((prev) => {
      const newIndex = prev === 0 ? schools.length - 1 : prev - 1;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  const handleRightArrowClick = () => {
    setSelectedSchool((prev) => {
      const newIndex = prev === schools.length - 1 ? 0 : prev + 1;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  const scrollToIndex = (index) => {
    const container = scrollContainerRef.current;
    const itemWidth = container.offsetWidth / divider;
    container.scrollTo({
      left: index * itemWidth,
      behavior: "smooth",
    });
  };

  const handleScrollBlock = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={`relative flex-none w-screen py-8 bg-backgroundLight dark:bg-backgroundDark`}
    >
      <div
        ref={scrollContainerRef}
        onWheel={handleScrollBlock}
        className="flex flex-row w-full overflow-hidden no-scrollbar"
      >
        {schools.map((school, index) => (
          <ItemHomeSchool
            key={index}
            school={school}
            isSelected={selectedSchool === index}
          />
        ))}
      </div>
      {/* Arrow Buttons */}
      <div className="hidden md:flex absolute top-16 start-[49.2%] items-center flex-col space-y-4">
        <ArrowButton isLeft={true} onClick={handleLeftArrowClick} />
        <ArrowButton onClick={handleRightArrowClick} />
      </div>
      <div className="absolute top-[48%] left-2 flex md:hidden">
        <ArrowButton isLeft={true} onClick={handleLeftArrowClick} />
      </div>
      <div className="absolute top-[48%] right-2 flex md:hidden">
        <ArrowButton onClick={handleRightArrowClick} />
      </div>
    </div>
  );
};
