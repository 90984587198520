import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { ItemNavigation } from "./items/ItemNavigation";
import {
  basePadding,
  basePaddingStart,
  iconBurgerMenu,
  iosArrowDown,
} from "../../util/ui_util";
import { AppContext } from "../../context/app/AppState";
import { ButtonWithAnimatedOpacity } from "./ButtonWithAnimatedOpacity";

const LanguageButton = () => {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useContext(AppContext);

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "ar" : "en");
    i18n.changeLanguage(language === "en" ? "ar" : "en");
  };

  return (
    <div
      className={`flex flex-row items-center space-x-4 rtl:space-x-reverse pe-2`}
    >
      <button
        onClick={toggleLanguage}
        className="px-4 py-2 border rounded text-text"
      >
        {language === "ar" ? "عربي" : "EN"}
      </button>
    </div>
  );
};

const TopBar = () => {
  const { primaryColor, secondaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-row w-full justify-end items-center ${basePadding}`}
      style={{ backgroundColor: primaryColor }}
    >
      <div>
        <ButtonWithAnimatedOpacity
          text={t("getCallBack")}
          bgColor={secondaryColor}
          textColor={"white"}
          onClick={() => {}}
        />
      </div>
      <div>
        <ButtonWithAnimatedOpacity
          text={t("enrolOnline")}
          bgColor={"transparent"}
          textColor={"white"}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

const MoreButton = ({ isMoreOpen, setIsMoreOpen }) => {
  const { primaryColor, secondaryColor, isRtl } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClick = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  return (
    <button
      className="relative flex flex-row h-full px-4 md:px-10 bg-navBarDialogBackground items-center justify-center space-x-2 md:space-x-4 rtl:space-x-reverse"
      onClick={handleClick}
    >
      <div
        className={`flex items-center cursor-pointer text-sm font-bold`}
        style={{ color: primaryColor }}
      >
        {t("More")}
      </div>
      {iosArrowDown({ fillColor: primaryColor })}
      {isMoreOpen && (
        <div
          className={`absolute w-[200px] top-full z-40 ${
            isRtl ? "left-0" : "right-0"
          }`}
        >
          <ButtonWithAnimatedOpacity
            text={t("getCallBack")}
            bgColor={secondaryColor}
            textColor={"white"}
            onClick={() => {}}
          />
          <ButtonWithAnimatedOpacity
            text={t("enrolOnline")}
            bgColor={primaryColor}
            textColor={"white"}
            onClick={() => {}}
          />
        </div>
      )}
    </button>
  );
};

export const NavigationBar = ({
  isBurgerMenuOpen,
  isMoreOpen,
  setIsMoreOpen,
  categories,
  showMore = false,
  onBurgerMenuClick,
}) => {
  const { isRtl } = useContext(AppContext);

  return (
    <nav className={`flex flex-col items-center`}>
      {!showMore && <TopBar />}
      <div
        className={`flex flex-row  w-full h-[80px] justify-between items-center ${
          showMore ? basePaddingStart : basePadding
        } bg-backgroundLight dark:bg-backgroundDark`}
        dir={isRtl ? "rtl" : "ltr"}
      >
        <NavLink to={"/"}>
          <img src={logo} alt="logo" className="h-[70px] object-fill" />
        </NavLink>
        <div
          className={
            "flex flex-row h-full space-x-4 md:space-x-8 rtl:space-x-reverse items-center"
          }
        >
          <div className="hidden lg:flex flex-row h-full items-center space-x-8">
            {categories.map((category, index) => (
              <React.Fragment key={index}>
                <ItemNavigation
                  category={category}
                  isRtl={isRtl}
                  flipPosition={index === categories.length - 1}
                />
                {index < categories.length - 1 && (
                  <div className="w-[2px] h-1/4 bg-[#E1E1E1]" />
                )}
              </React.Fragment>
            ))}
          </div>
          <LanguageButton />
          {/* START: This right layout appears on md screens */}
          <div className="flex lg:hidden">
            <div className={`${isBurgerMenuOpen ? "hidden" : "block"}`}>
              <button
                onClick={onBurgerMenuClick}
                className="text-black focus:outline-none"
              >
                {iconBurgerMenu}
              </button>
            </div>
          </div>
          {/* END: This right layout appears on md screens */}
          {showMore && (
            <MoreButton isMoreOpen={isMoreOpen} setIsMoreOpen={setIsMoreOpen} />
          )}
        </div>
      </div>
    </nav>
  );
};
