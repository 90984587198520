import React from "react";
import { dummySocials } from "../../../util/dummy";
import logo from '../../../assets/images/logo.png'

export const FooterSocials = () => {
  const socials = dummySocials;

  return (
    <div className="flex flex-col space-y-16 pt-2 lg:flex-shrink-0 lg:flex-grow-0 lg:basis-[20%]">
      <div className="flex flex-row space-x-4 rtl:space-x-reverse">
        {socials.map((social, index) => (
          <img
            key={index}
            src={social.image}
            alt=""
            className="w-8 h-8 object-fill rounded-full cursor-pointer"
            onClick={() => {
              window.open(social.url, "_blank");
            }}
          />
        ))}
      </div>
      <img src={logo} alt="" className="w-44 object-cover" />
    </div>
  );
};
