import React, { useContext, useRef, useState, useEffect } from "react";
import { basePadding } from "../../../util/ui_util";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import { ItemHomeResult } from "./items/ItemHomeResult";
import { AppButton } from "../../base/AppButton";
import { dummyHomeResults } from "../../../util/dummy";
import { useDraggable } from "react-use-draggable-scroll";

export const HomeResults = () => {
  const { primaryColor, secondaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  const scrollContainerRef = useRef(null);
  const { events } = useDraggable(scrollContainerRef);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Default to 4 items per page

  const results = dummyHomeResults;
  const totalPages = Math.ceil(results.length / itemsPerPage);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.matchMedia("(min-width: 1024px)").matches) {
        setItemsPerPage(4);
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(1);
      }
    };
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  // Track the current page based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      const pageWidth = container.offsetWidth;
      const newPage = Math.round(container.scrollLeft / pageWidth);
      setCurrentPage(newPage);
    };

    const container = scrollContainerRef.current;
    container?.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToPage = (pageIndex) => {
    const container = scrollContainerRef.current;
    const pageWidth = container.offsetWidth;
    container.scrollTo({
      left: pageIndex * pageWidth,
      behavior: "smooth",
    });
    setCurrentPage(pageIndex);
  };

  return (
    <div className={`w-full py-8 ${basePadding} md:px-0`}>
      <div className="container mx-auto flex flex-col items-center w-full space-y-4">
        <h2
          className="text-3xl font-bold text-center"
          style={{ color: primaryColor }}
        >
          {t("outstandingGMSResults")}
        </h2>
        <h4
          className="text-xl font-bold text-center"
          style={{ color: primaryColor }}
        >
          {t("homeResultsText")}
        </h4>
        {/* Horizontal Pager with Snap */}
        <div
          {...events}
          ref={scrollContainerRef}
          className="flex w-full overflow-x-scroll space-x-8 rtl:space-x-reverse no-scrollbar pt-12"
        >
          {results.map((result, index) => (
            <div
              key={index}
              className="flex-shrink-0 snap-start w-full"
              style={{ width: `calc(100% / ${itemsPerPage})` }} // Adjust based on itemsPerPage
            >
              <ItemHomeResult number={result.number} text={result.text} />
            </div>
          ))}
        </div>
        {/* Pagination Dots */}
        <div className="flex justify-center mt-4 space-x-2">
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <button
              key={pageIndex}
              onClick={() => goToPage(pageIndex)}
              className={`h-2 w-2 rounded-full ${
                pageIndex === currentPage ? "bg-green-600" : "bg-gray-400"
              }`}
            ></button>
          ))}
        </div>
        {/* Button */}
        <div className="w-[200px] pt-12">
          <AppButton
            text={t("readMore")}
            bgColor={"transparent"}
            bgHoverColor={"transparent"}
            borderColor={primaryColor}
            borderHoverColor={secondaryColor}
            textColor={primaryColor}
            textHoverColor={secondaryColor}
          />
        </div>
      </div>
    </div>
  );
};
