import React from "react";
import { dummyFooterPartners } from "../../../util/dummy";
import { basePadding } from "../../../util/ui_util";

export const FooterPartners = () => {
  const images = dummyFooterPartners;
  return (
    <div
      className={`flex flex-wrap lg:flex-row w-full gap-x-12 gap-y-4 ${basePadding} lg:px-48 py-16`}
    >
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt="" className="w-[120px] object-contain" />
        </div>
      ))}
    </div>
  );
};
