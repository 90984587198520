import React from "react";
import colors from "../../../util/colors";
import { FooterPartners } from "./FooterPartners";
import { FooterSocials } from "./FooterSocials";
import { FooterLinks } from "./FooterLinks";
import { useTranslation } from "react-i18next";
import { basePadding } from "../../../util/ui_util";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col w-full divide-y divide-gray-300"
      style={{ backgroundColor: colors.footerBackground }}
    >
      <FooterPartners />
      <div className={`flex flex-wrap lg:flex-nowrap lg:flex-row gap-x-4 gap-y-4 justify-between ${basePadding} lg:px-48 pb-8`}>
        <FooterSocials />
        <FooterLinks />
      </div>
      <div className={`w-full flex flex-col md:flex-row md:justify-between items-center space-y-2 md:space-y-0 bg-[#E3E6E6] ${basePadding} lg:px-48 py-8`}>
        <div className="flex flex-row space-x-4 rtl:space-x-reverse">
          <div
            className="text-gray-500 hover:text-gray-400 text-sm cursor-pointer"
            onClick={() => {}}
          >
            {t("privacyPolicy")}
          </div>
          <div className="text-gray-500 text-sm">.</div>
          <div
            className="text-gray-500 hover:text-gray-400 text-sm cursor-pointer"
            onClick={() => {}}
          >
            {t("termsConditions")}
          </div>
        </div>
        <div className="text-gray-500 text-[10px]">{t("copyRights")}</div>
      </div>
    </div>
  );
};
