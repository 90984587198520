const colors = {
  primary: "#1B3884",
  secondary: "#EF3E27",
  accent: "#F4B333",
  backgroundLight: "#FFFFFF",
  backgroundDark: "#000000",
  textLight: "#000000",
  textDark: "#FFFFFF",
  success: "#10B981",
  warning: "#FBBF24",
  danger: "#EF4444",
  extendedBurgerMenuBackground: "#0E0E0F",
  navbarBackground: "#151936",
  loginDialogBackground: "#F8F9FB",
  disabledButton: "#94989A",
  navBarDialogBackground: "#E3E6E6",
  footerBackground: "#E8EDED",
  appPurple: "#8C1D58",
};

export function getPrimaryColor() {
  return colors.primary;
}

export function getSecondaryColor() {
  return colors.secondary;
}

export function getAccentColor() {
  return colors.accent;
}

export default colors;
