import React, { useContext, useState } from "react";
import { AppContext } from "../../../../context/app/AppState";
import { addOpacityToHex } from "../../../../util/ui_util";

export const ItemHomeButton = ({ text, icon, onClick }) => {
  const { primaryColor } = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="flex flex-row w-full justify-center items-center space-x-2 rtl:space-x-reverse py-4"
      style={{ backgroundColor: isHovered ? addOpacityToHex(primaryColor, 0.8) : primaryColor }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={icon} alt="" className="w-[25px]" />
      <h4 className="text-white font-bold">{text}</h4>
    </button>
  );
};
